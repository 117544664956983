"use client";

import { apiAuthSession } from "@/lib/api-method";
import { Button, Nav, NavDropdown } from "react-bootstrap";
import { Session } from "next-auth";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react"

export default function UserComponent() {
  //const [ session, setSession ] = useState<Session | null>(null);
  const pathname = usePathname();

  /*
  useEffect(() => {
    apiAuthSession()
      .then(([status, session]) => {
        if (status == 200)
          setSession(session);
      });
  }, []);
  */

  const { data: session } = useSession()

  if (session != null && session.user != null) {
    return (
      <Nav>
        <NavDropdown title={ session.user.name }>
          <NavDropdown.Item href={ `/@${session.user.username}` }>
            My Profile
          </NavDropdown.Item>
          <NavDropdown.Item href={ `/api/nextauth/signout?callbackUrl=${pathname}` }>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
  else {
    return (
      <Button href={ `/api/nextauth/signin?callbackUrl=${pathname}` }>
        Login/Register
      </Button>
    )
  }
}