"use client";

import { apiBlogs } from "@/lib/api-method";
import { useEffect, useState } from "react";
import Blog from "@/backend-models/blog";
import BlogComponent from "@/components/blogs/blog";
import BlogPlaceholderComponent from "@/components/blogs/placeholder";
import Link from "next/link";
import Paginate from "@/components/paginate";

export default function Component(
  { page, limit }: { page: number | null, limit: number | null }
) {
  if (page == null) {
    page = 1;
  }
  if (limit == null) {
    limit = 10;
  }

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const fetchData = async () => {
    setIsLoading(true);

    const [status, newBlogs, total] = await apiBlogs(page, limit);
    
    if (newBlogs.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setBlogs([...blogs, ...newBlogs]);
    setTotal(total);
    setIsLoading(false);
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop <= document.documentElement.offsetHeight - 10 || isLoading) {
      return;
    }
    fetchData();
  };

  useEffect(() => {
    if (blogs.length === 0 && !isLoading) {
      fetchData();
    }

    //window.addEventListener('scroll', handleScroll);
    //return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, blogs]);

  return (
    <div>
      <Paginate
        url="/blogs"
        page={page}
        limit={limit}
        total={total}
      />
      <div className="row g-4">
        {blogs.map((blog: Blog, index: number) => (
          <BlogComponent blog={blog} key={index} />
        ))}
        {isLoading && (
          <BlogPlaceholderComponent />
        )}
      </div>
      <Paginate
        url="/blogs"
        page={page}
        limit={limit}
        total={total}
      />
    </div>
  );
}