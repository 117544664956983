import { FormEvent } from "react";
import { IPost } from "@/models/post";
import { IUser } from "@/models/user";
import { Session } from "next-auth";
import Agent from "@/backend-models/agent";
import AgentWeight from "@/backend-models/agent_weight";
import Blog from "@/backend-models/blog";
import Trading from "@/backend-models/trading";
import TradingAgent from "@/backend-models/trading_agent";
import TradingAgentState from "@/backend-models/trading_agent_state";
import TradingAgentTransaction from "@/backend-models/trading_agent_transaction";

export async function apiAgent(id: string): Promise<[number, Agent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/agents/" + id);
  const data = await response.json();

  return [response.status, data];
}

export async function apiAgents(): Promise<[number, Agent[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/agents");
  const data = await response.json();

  return [response.status, data];
}

export async function apiAgentWeight(id: string): Promise<[number, AgentWeight]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/agent_weights/" + id);
  const data = await response.json();

  return [response.status, data];
}

export async function apiAgentWeights(): Promise<[number, AgentWeight[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/agent_weights");
  const data = await response.json();

  return [response.status, data];
}

export async function apiAuthSession(): Promise<[number, Session]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/nextauth/session");
  const data = await response.json();

  return [response.status, data];
}

export async function apiBlogs(
  page: number,
  limit: number,
): Promise<[number, Blog[], number]> {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/blogs?page=${page}&limit=${limit}`);
  const data = await response.json();

  return [
    response.status,
    data["blogs"] as Blog[],
    data["total"] as number
  ];
}

export async function apiBlog(id: string): Promise<[number, Blog]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/blogs/" + id);
  const data = await response.json();
  return [response.status, data];
}

export async function apiBlogCreate(event: FormEvent<HTMLFormElement>): Promise<[number, Blog]> {
  const formData = new FormData(event.currentTarget);
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/blogs", {
    body: formData,
    cache: "no-store",
    method: "POST",
  });
  const data = await response.json();
  return [response.status, data];
}

export async function apiBlogUpdate(event: FormEvent<HTMLFormElement>, blog: Blog): Promise<[number, Blog]> {
  const formData = new FormData(event.currentTarget);

  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/blogs/" + blog.id, {
    body: formData,
    cache: "no-store",
    method: "PATCH",
  });
  const data = await response.json();
  return [response.status, data];
}

export async function apiBlogDelete(id: string): Promise<number> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/blogs/" + id, {
    method: "DELETE"
  });
  return response.status;
}

export async function apiDataset(id: string, interval: number): Promise<[number, any]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/datasets/" + id + ".json?interval=" + interval);
  const data = await response.json();

  return [response.status, data];
}

export async function apiPost(id: string): Promise<[number, IPost]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/posts/" + id);
  const data = await response.json();

  return [response.status, data];
}

export async function apiPostCreate(event: FormEvent<HTMLFormElement>, post: IPost): Promise<[number, IPost]> {
  const formData = new FormData(event.currentTarget);

  post.contents.forEach((content, index) => {
    if (content.attachment_file != null) {
      formData.append(`contents[${index}][attachment_file]`, content.attachment_file);
    }
  })

  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/posts", {
    body: formData,
    cache: "no-store",
    method: "POST",
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiPostLike(id: string): Promise<[number, IPost]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/posts/" + id + "/like");
  const data = await response.json();

  return [response.status, data];
}

export async function apiPosts(last_created_at?: Date, username?: string): Promise<[number, IPost[]]> {
  const url = new URL(process.env.NEXT_PUBLIC_API_URL + "/posts");
  if (last_created_at) {
    url.searchParams.append("last_created_at", last_created_at.toString());
  }
  if (username) {
    url.searchParams.append("username", username);
  }

  const response = await fetch(url.toString());
  const data = await response.json();

  return [response.status, data];
}

export async function apiPostUnlike(id: string): Promise<[number, IPost]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/posts/" + id + "/unlike");
  const data = await response.json();

  return [response.status, data];
}

export async function apiPostUpdate(event: FormEvent<HTMLFormElement>, post: IPost): Promise<[number, IPost]> {
  const form_data = new FormData(event.currentTarget);

  post.contents.forEach((content, index) => {
    if (content.attachment_file != null) {
      form_data.append(`contents[${index}][attachment_file]`, content.attachment_file);
    }
  })

  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/posts/" + post._id, {
    body: form_data,
    cache: "no-store",
    method: "PATCH",
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiProduct(slug: string): Promise<[number, any]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/products/" + slug);
  const data = await response.json();

  return [response.status, data];
}


export async function apiProducts(): Promise<[number, any[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/products");
  const data = await response.json();

  return [response.status, data];
}

export async function apiTrack(name: string, params: {}): Promise<[number, {}]> {
  const body = {
    name: name,
    params: params
  }
  const response = await fetch(
    process.env.NEXT_PUBLIC_API_URL + "/track",
    {
      body: JSON.stringify(body),
      cache: "no-store",
      method: "POST"
    }
  );
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradings(enabled: boolean): Promise<[number, Trading[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings?enabled=" + enabled);
  const data = await response.json();

  return [response.status, data];
}

export async function apiTrading(id: string): Promise<[number, Trading]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + id);
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingCreate(event: FormEvent<HTMLFormElement>, trading: Trading): Promise<[number, Trading]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings", {
    body: JSON.stringify(trading),
    cache: "no-store",
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST"
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingUpdate(event: FormEvent<HTMLFormElement>, trading: Trading): Promise<[number, Trading]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading.id, {
    body: JSON.stringify(trading),
    cache: "no-store",
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST"
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgents(trading_id: string): Promise<[number, TradingAgent[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents");
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgent(trading_id: string, agent_id: string): Promise<[number, TradingAgent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent_id);
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentCreate(trading_id: string, agent: TradingAgent): Promise<[number, TradingAgent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(agent)
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentUpdate(trading_id: string, agent: TradingAgent): Promise<[number, TradingAgent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent.id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(agent)
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentDelete(trading_id: string, agent_id: string): Promise<number> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent_id, {
    method: "DELETE"
  });

  return response.status;
}

export async function apiTradingAgentStates(tradingId: string, agentId: string, onlyChanged: boolean): Promise<[number, TradingAgentState[]]> {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/tradings/${tradingId}/agents/${agentId}/states?onlyChanged=${onlyChanged}`);
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentTransactions(trading_id: string, agent_id: string): Promise<[number, TradingAgentTransaction[]]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent_id + "/transactions");
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentTransactionAllocate(trading_id: string, agent_id: string, amount: number): Promise<[number, TradingAgent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent_id + "/transactions/allocate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ amount: amount })
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiTradingAgentTransactionDeallocate(trading_id: string, agent_id: string, amount: number): Promise<[number, TradingAgent]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/tradings/" + trading_id + "/agents/" + agent_id + "/transactions/deallocate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ amount: amount })
  });
  const data = await response.json();

  return [response.status, data];
}

export async function apiUser(id: string): Promise<[number, IUser]> {
  const response = await fetch(process.env.NEXT_PUBLIC_API_URL + "/users/" + id);
  const data = await response.json();

  return [response.status, data];
}
