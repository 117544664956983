"use client";

import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { SessionProvider } from "next-auth/react";
import { usePathname } from "next/navigation";
import CookieConsent from "react-cookie-consent";
import Image from "next/image";
import User from "./user";

export default function MyNavbar() {
  const pathname = usePathname();
  
  return (
    <>
      <Navbar
        expand="lg"
        className="navbar navbar-expand-lg bg-black text-white"
        data-bs-theme="dark"
      >
        <Container>
          <Navbar.Brand className="navbar-brand" href="/">
            <Image
              alt="Logo"
              height={50}
              src="/images/logo-white.png"
              width={50}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                className={ pathname == "/" ? "nav-link active" : "nav-link" }
                aria-current="page"
                href="/"
              >
                Home
              </Nav.Link>
              <NavDropdown title="Trading Agents" id="basic-nav-dropdown">
                <NavDropdown.Item href="/agents">Accounts & Agent</NavDropdown.Item>
                <NavDropdown.Item href="/agents/agents">Agents</NavDropdown.Item>
                <NavDropdown.Item href="/agents/weights">Weights</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <div className="d-flex">
              <SessionProvider baseUrl={process.env.NEXT_PUBLIC_WEB_URL} basePath={"/api/nextauth"}>
                <User />
              </SessionProvider>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="Understand!"
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-warning my-2 text-center"
          contentClasses="text-capitalize"
        >
          We use cookies to enhance your experience, including for login and tracking behavior to improve our services.
        </CookieConsent>
      </Container>
    </>
  )
}