import Link from "next/link"

export default function Component(
  { url, page, limit, total }: { url: string, page: number | null, limit: number | null, total: number }
) {
  return (
    <div className="d-flex justify-content-center">
      <nav>
        <ul className="pagination">
          {page && page > 1 && (
            <li className="page-item">
              <a href={`${url}?page=${page - 1}&limit=${limit}`} className="page-link">
                <i className="bi bi-chevron-compact-left"></i>
              </a>
            </li>
          )}
          {Array.from({ length: Math.ceil(total / (limit || total)) }, (_, i) => i + 1).map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber ? "active" : ""}`}>
              <a href={`${url}?page=${pageNumber}&limit=${limit}`} className="page-link">
                {pageNumber}
              </a>
            </li>
          ))}
          {page && page < Math.ceil(total / (limit || total)) && (
            <li className="page-item">
              <a href={`${url}?page=${page + 1}&limit=${limit}`} className="page-link">
                <i className="bi bi-chevron-compact-right"></i>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}
