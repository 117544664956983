import Blog from "@/backend-models/blog";
import Content from "@/backend-models/content";
import ContentComponent from "@/components/content";
import dayjs from "dayjs";
import Link from "next/link";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default function Component(
  { blog }: { blog: Blog }
) {
  return (
    <div className="col-12 mb-4">
      <div className="card shadow-sm">
        { blog.image ? (
          <Link
            href={`/blogs/${blog.slug}`}
            className="text-decoration-none text-dark"
            title={blog.title}
          >
            <img
              src={blog.image.access_path}
              className="card-img-top"
              alt={blog.title}
            />
          </Link>
        ) : null }
        <div className="card-body">
          <div className="card-title">
            <h2>
              <Link
                href={`/blogs/${blog.slug}`}
                className="text-decoration-none text-dark"
                title={blog.title}
              >
                { blog.title }
              </Link>
            </h2>
            <p className="text-muted">
              <i className="bi bi-person-fill me-2"></i>
              { blog.user?.name || "Unknown User" }
              &nbsp;
              <i className="bi bi-clock-fill me-2"></i>
              { dayjs(blog.created_at).fromNow() }
              &nbsp;
            </p>
          </div>
          <div className="card-text">
            <div className="px-3 py-2">
                { blog.contents.slice(0, 1).map((content: Content, index: number) => (
                <ContentComponent content={content} show_asset={false} key={index} />
                ))}
            </div>
          </div>
          <div className="card-footer text-end">
            <Link
              href={`/blogs/${blog.slug}`}
              className="btn btn-primary btn-lg px-4 me-md-2"
            >
              <i className="bi bi-arrow-right-circle"></i>
              &nbsp;
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
