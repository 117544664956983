import Content from "@/backend-models/content"
import Image from "next/image";
import Markdown from 'react-markdown';

export default function Component(
  { content, show_asset }: { content: Content, show_asset?: boolean }
) {
  var text = content.text ?? "";

  if (!show_asset) {
    text = text.slice(0, 1000) + "...";
  }

  return (
    <div className="my-4">
      { show_asset && content.asset && typeof content.asset === "object" && "access_path" in content.asset && (
        <Image
          alt={typeof content.asset === "object" && "text" in content.asset ? String(content.asset.text) : ""}
          className="img-fluid"
          src={typeof content.asset === "object" && "access_path" in content.asset ? content.asset.access_path : ""}
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: "100%", height: "auto" }} 
        />
      )}
      <Markdown>{text}</Markdown>
    </div>
  )
}